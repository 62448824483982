.job-card{
    padding: 20px;
    border: 2px solid #f5f5f5;
    border-radius: 8px;
    box-shadow: 1px 3px 15px 2px rgb(216 216 216 / 26%);
    margin-bottom: 30px;
}
.job-title{
    color: #131313;
    font-size: min(max(16px, calc(1rem + ((1vw - 3.2px) * 0.75))), 28px);
    font-family: 'Gustavo';
    font-weight: 700;
}
.d-flex p span{
    color: #131313;
    font-size: min(max(14px, calc(0.875rem + ((1vw - 3.2px) * 0.25))), 18px);
    font-family: 'Inter';
    font-weight: 500;
    margin-left: 12px;
    position: relative;
    top: 3px;
}
.description{
    color: #666666;
    font-size: min(max(14px, calc(0.875rem + ((1vw - 3.2px) * 0.25))), 18px);
    font-family: 'Inter';
    font-weight: 500;  
}
.view_link{
    color: #5259FF;
    font-size: 16px;
    font-family: 'Inter';
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;   
}
.ml-30{
    margin-left: 30px;
}
.job-d-flex{
    display: flex;
}

.career-position{
    margin: 3rem 0;
}
.career-position h2{
    font-size: min(max(20px, calc(1.25rem + (38 - 20) * ((100vw - 320px) / (1400 - 320)))), 38px);
    color: #131313;
    font-weight: 800;
    margin-bottom: 20px;
    font-family: gustavo;
}
.positions{
    background: #F8F8F8;
    padding: 20px;
}
.no-positions{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
}
.no-positions p{
    padding-top: 20px;
    text-align: center;
    font-family: Inter;
    font-size: min(max(20px, calc(1.25rem + (20 - 20) * ((100vw - 320px) / (1400 - 320)))), 18px);
    font-weight: 500;
    line-height: 27px;
    margin-bottom: 0;
    color: #787878;
}

.action-btn{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.view_detail_btn{
    border: 2px solid #131313;
    background: #fff;
    color: #131313;
    font-weight: 600;
    padding: 4px 50px 6px 50px;
    margin: 10px 0;
    border-radius: 2px;
}

.view_detail_btn:hover{
    border: 2px solid #131313;
    background: #131313;
    color: #fff;
}

@media (max-width: 489px){
    .job-d-flex{
        display: unset !important;
        margin-bottom: 15px !important;
    }
    .job-d-flex p{
        margin-left: 0px !important;
        margin-bottom: 3px !important;
    }
    .job-d-flex img{
        width: 20px;
        height: 20px;
    }
    .job-d-flex span{
        margin-left: 7px !important;
        top: 2px !important;
    }
}
