.key_card{
    width: 100%;
    background-color: #FAFAFA;
    margin-bottom: 30px;
    padding: 36px;
    height: 445px !important;
}
.key_cardlast{
    height: auto !important;
}
.key_icons{
    margin-bottom: 36px;
}
.key_card_content h2{
    font-size: clamp(1.125rem, 0.9126rem + 1.1327vi, 2rem);
    font-family: gustavo;
    font-weight: 500;
    color: #131313;
    margin-bottom: 22px;
}
.key_card_content h3{

    font-size: clamp(0.9375rem, 0.892rem + 0.2427vi, 1.125rem);
    font-family: Inter, 'Arial Narrow Bold', sans-serif;
    font-weight: 400;
    color: #333333;
    opacity: 75%;
    text-align: justify;
    margin-bottom: 24px;
   

}

.key_card_content p{
    font-size: clamp(0.9375rem, 0.892rem + 0.2427vi, 1.125rem);
    font-family: Inter, 'Arial Narrow Bold', sans-serif;
    font-weight: 600; 
    color: #0048BD;
    cursor: pointer;
}
.key_card_content p svg{
    transition: 0.35s all ease-in-out;

}
.key_card_content p:hover svg{
    transform: translateX(5px);
     
}
.key_card_content p:hover{
    color: #002868;
     
}

@media screen and (max-width:992px) {
   
    .key_card{
        height: auto !important;
        padding-bottom: 20px !important;
    }
    }

@media screen and (max-width:800px) {
    .key_icons{
        height: 66px;
        width: 66px;
    }
    .key_serv:last-child .key_card{
        margin-bottom: 0px;
    }
    
}

@media screen and (min-width:990px) and (max-width:1380px){
    .key_card{
        height: 400px;
    }
}