
.careerbanner-bg{
    background-image: url('../../assets/images/career-bg.png');
    padding: 120px 0;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
}

.banner-content h1{
    font-size: min(max(20px, calc(1.25rem + (60 - 20) * ((100vw - 320px) / (1400 - 320)))), 60px);
    color: white;
    font-weight: 800;
    margin-bottom: 20px;
    line-height: 70px;
    font-family: gustavo;
}
.banner-content h1 span{
    color: #afbff8;
}
.banner-content p{
    color: #fff;
    font-family: Inter;
    font-size: min(max(20px, calc(1.25rem + (20 - 20) * ((100vw - 320px) / (1400 - 320)))), 18px);
    font-weight: 500;
    line-height: 27px;
    margin-bottom: 0;
    padding-top: 12px;
}

@media screen and (max-width: 991px) {
    .careerbanner-bg{
        padding: 40px 0 !important;
    }
    .banner-content h1{
        /* margin-bottom: 0; */
        line-height: 30px;
    }
}

@media screen and (max-width: 660px) {
    .banner-content h1{
        margin-bottom: 0;
    }
}