.explore-content {
    padding-right: 20px;
}

.explore-content h2 {
    font-size: min(max(20px, calc(1.25rem + (38 - 20) * ((100vw - 320px) / (1400 - 320)))), 38px);
    color: #131313;
    font-weight: 800;
    margin-bottom: 20px;
    font-family: gustavo;
}

.explore-content p {
    color: #505050;
    font-family: Inter;
    font-size: min(max(20px, calc(1.25rem + (20 - 20) * ((100vw - 320px) / (1400 - 320)))), 18px);
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 0;
    padding-top: 12px;
}

.explore-img img{
    width: 100%;
}

@media screen and (min-width: 991px) {
    .explore-content h2 {
        margin-right: 30%;
    }  
}


@media screen and (max-width: 992px) {
    .explore-img{
        margin-top: 40px;
    }
    .explore-content h2 {
        margin-bottom: 0;
    }
}