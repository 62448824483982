.service-section{
    background: white;
}
.bg-grey{
    background: #FBFBFB;
}
.service-section h3{
    color: #131313;
    font-size: 40px;
    font-weight: 600;
    padding-top: 50px;
    padding-bottom: 20px;
    font-family: 'gustavo';
}
.service-card{
    background: transparent;
    border: none;
    box-shadow: none !important;
    padding: 0px !important;
}
.service-card-body{
    padding: 0px;
}
.service-card-title{
    color: #131313;
    font-size: 30px;
    font-weight: 500;
    font-family: 'gustavo-medium';
}
.service-card-content{
    color: #666666;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-align: justify;
    font-family: 'Inter';
}
.service-card-subtitle{
    font-size: 24px;
    color: #0048BD;
    padding-top: 20px;
    padding-bottom: 15px;
    font-family: 'Inter';
    font-weight: 600;
}
.service-card li{
    color: #666666;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    font-family: 'Inter';  
}
.service-card-button{
    background: white;
    color: #3A65FF;
    border: 2px solid #3A65FF;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Inter';
    padding-right: 30px;
    padding-left: 30px;
}
.row-reverse{
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}
@media(max-width: 540px){
    .service-section .mt-5{
        margin-top: 0px !important;
    }
    .service-card img{
        margin-top: 15px;
    }
    .service-section h3{
        font-size: 30px;
    }
   .service-card-title{
        font-size: 22px;
    }
    .service-card-subtitle {
        font-size: 20px;
    }
}