.banner-section.page{
    width: 100%;
    display: flex;
    align-items: center;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    height: 445px;
}
.page-title{
    font-size: clamp(1.75rem, 1.1579rem + 3.1579vi, 4rem);
    color: white;
    font-weight: 700;
    font-family: gustavo;
    line-height: 83.2px;
}
.smalltext{
    color: #A5B9FF;
    font-size: min(max(15px, calc(0.9375rem + (24 - 15) * ((100vw - 320px) / (1400 - 320)))), 24px);
    font-family: 'Inter';
    font-weight: 700;
    margin-bottom: 0;

}
.banner_butn{
    line-height: 19.36px !important;
}

@media screen and (max-width:800px) {
    .banner-section.page {
        height: 250px;
        background-size: cover !important;
        background-position:top center !important;
     }
     .banner_img_case{
        display: none;
     }
     .img_col_case{
        display: none;
     }
     .page-title{
        line-height: 50px;
        
     }
     
    
}
@media screen and (max-width:500px) {

    .page-title{
        line-height: 30px;
     }
}
@media screen and (min-width:800px) and (max-width: 1056px) {

.banner_img_case{
    max-width: 85%;
}
/* .banner-section {
    height: 300px;
} */
}