.What_sets_us_header h2 {
    font-family: 'Gustavo', sans-serif;
    font-weight: 700;
    font-size:  clamp(1.25rem, 0.9211rem + 1.7544vi, 2.5rem);
    line-height: 40px;
  }
  .What_sets_us_container{
     background-color: #131313;
     color: #FFFFFF;
  }
 .What_sets_us_card_content h2{
    font-family: 'Gustavo', sans-serif;
    font-weight: 700;
    font-size: clamp(1.0625rem, 0.9474rem + 0.614vi, 1.5rem);
    line-height: 30px;
    color: #FFFFFF;
    opacity: 85%;
    margin: 0px;
 }
 .What_sets_us_card_content p{
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: clamp(0.875rem, 0.8421rem + 0.1754vi, 1rem);
    line-height: 24px;
    color: #FFFFFF;
    opacity: 75%;
    padding-right: 45px;
 }
 .borderbottom {
    border-bottom: 1px solid #6E6E6E !important;
}

.border-right {
    border-right: 1px solid #6E6E6E !important;
}
.border-right:last-child{
   border: none !important;
}

@media screen and (max-width:1100px) and (max-width:1056px) {

   .What_sets_us_card_content p {
      padding-right: 0px !important;
   }
   
}

  
  @media screen and (max-width:992px) {
   .What_sets_us_card {
      border-right: none !important;
     
  }
  .What_sets_us_card.border-right {
   border-right: none !important;
}
.What_sets_us_header h2 {
   padding: 20px !important;
   padding-bottom: 0px !important;
}
.border-right {
   border: none !important;
}
.What_sets_us_row .What_sets_us_header{
   padding: 0px 0px 0px 0px !important;
}
.What_sets_us_row.pe-5 {
   padding-left: 0 !important;
}
.What_sets_us_row .pb-5 {
   padding-bottom: 0 !important;
}
.What_sets_us_row .pe-5{
   padding-right: 0 !important;
}
.What_sets_us_card_content h2{
  line-height: 24px;
}
.What_sets_us_card_content p{
   padding-right: 0px !important;
}

  }
  