

.left-div h1{
    font-family: Gustavo;
    font-size: min(max(20px, calc(1.25rem + (44 - 20) * ((100vw - 320px) / (1400 - 320)))), 44px);
    font-weight: 500;
    line-height: 48px;
    text-align: left;
    color: rgba(19, 19, 19, 0.75);
}

.span{
    font-weight: 700;
    opacity: 100%;
    color: #131313;
}
.left-div p{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.01em;
    text-align: left;

}
.right-div {
    height: 500px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
  }
  .left-content{
    position: relative;
  }
  .title-loader{
    display: flex;
    position: absolute;
    width: 90%;
    top: 35px;
    right: 10px;
  }
.panel-loader{
    position: relative;
    height: 8px;
    width: 30%;
    background: #CECECE;
    border-radius: 50px;
    margin-right: 15px;
    list-style-type: none;
    }
    
.panel-loader.active{
    color: #3A65FF;
    font-weight: 500;
    background: #CECECE;
    border-radius: 50px;
}
.panel-loader.active::after{
    content: '';
    position: absolute;
    width: 0%;
    height: 8px;
    background: #3A65FF;
    border-radius: 50px;
    left: 0;
    bottom: 0px;
    animation: newfade 7s;
} 


.div-loader h1{
    font-family: Gustavo;
    font-size: min(max(20px, calc(1.25rem + (40 - 20) * ((100vw - 320px) / (1400 - 320)))), 40px);
    /* font-size: 40px; */
    font-weight: 700;
    line-height: 38px;
    text-align: left;
    color: #FFFFFF;
    margin-top: 20px;
    position: absolute;
    top: 50px;
    left: 100px;
} 

/************************* Animations*********************** */
@keyframes newfade {

    100%{
        width: 100%;
    }

}

@media screen and (max-width:991px) {
    .left-div{
        height: auto;
    }
    .left-div p{
        padding-right: 0px !important;
    
    }
    .div-loader{
        padding: 0 !important;
    }
    .div-loader img{
        width: 100% !important;
    }
}

@media screen and (max-width: 600px) {
    .div-loader h1{
        left: 60px !important;
    }
    .left-div h1{
        line-height: 30px !important;
    }
    .right-div{
        height: unset !important;
    }
    .pe-5{
        padding-right: 0 !important;
    }
    .ms-5{
        margin-left: 0 !important;
    }
}