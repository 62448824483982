.case_study_main_title{
    font-size: clamp(1rem, 0.8684rem + 0.7018vi, 1.5rem);
    font-weight: 700;
    color: #0048BD;
    font-family: 'inter', sans-serif;
    margin: 0px;
    line-height: 45px;

}
.case_study_main_subtitle{
    font-family: gustavo;
    font-size: clamp(1.125rem, 0.7961rem + 1.7544vi, 2.375rem);
    font-weight: 700;
    color: #131313;
    margin: 0px;
    margin-top: 28px;

}

@media screen  and (max-width: 992px) {
    #Overview .banner-section{

        height: 180px !important;
    
    }
    .case_study_section.mt-75{


        margin-top: 40px;

    }
    .case_study_main_subtitle{
        margin-top: 16px;
    }
}


@media screen  and (max-width: 540px) {

    .case_study_main_subtitle {
        margin-top: 0px;
    }
    .case_study_main_title {
        font-size: 18px;
    }


}