.core-team-header h2 {
    font-family: 'Gustavo', sans-serif;
    font-weight: 700;
    font-size: clamp(1.25rem, 0.9211rem + 1.7544vi, 2.5rem);
    line-height: 40px;
}

.core-team-header p {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: clamp(1rem, 0.9342rem + 0.3509vi, 1.25rem);
    line-height: 32px;
    color: #333333;
    opacity: 85%;
}

.core-team-card {
    height: auto;
}

.core-team-card img{
    height: auto;
    object-fit: cover;
}


.core-team-card h2 {
    font-size: clamp(1.125rem, 0.9126rem + 1.1327vi, 28px);
    font-family: 'Gustavo', sans-serif;
    font-weight: 500;
    color: #131313;
    line-height: 38px;
    margin-top: 8px;
}

.core-team-card h3 {
    font-size: clamp(0.9375rem, 0.892rem + 0.2427vi, 18px);
    font-family: 'Inter', 'Arial Narrow Bold', sans-serif;
    font-weight: 400;
    color: #333333;
    opacity: 75%;
    margin-bottom: 24px;
}

@media screen and (max-width: 991px) {
    .core-team-header p {
        line-height: 28px;
        margin-top: 0px !important;
        text-align: justify;
    }
  
    
}


@media screen and (max-width:540px) {

    .mt-50.section_core{
        margin-top: 35px;
    }
    
}