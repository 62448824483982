.apply_now_btn {
    border: 1.4px solid #3A65FF;
    background: #3A65FF;
    color: white;
    font-weight: 600;
    padding: 5px 50px 8px 50px;
    margin: 10px 0;
    border-radius: 2px;
    margin-right: 20px;
}

.apply_now_btn:hover {
    background: #4e0fa5;
    color: white !important;
}