.spectrum-title {
  font-size: min(
    max(20px, calc(1.25rem + (38 - 20) * ((100vw - 320px) / (1440 - 320)))),
    38px
  );
  font-family: gustavo;
  margin-bottom: 0;
}
.spectrum-sub-title {
  font-size: min(
    max(16px, calc(1rem + (30 - 16) * ((100vw - 320px) / (1440 - 320)))),
    30px
  );
  font-family: gustavo;
  margin-bottom: 70px;
}
.pointer-div {
  cursor: pointer;
}
.spectrum_box {
  border-radius: 16px;
  border: 2px solid #dcdcdc;
  background: #fff;
  box-shadow: 1px 3px 15px 0px rgba(216, 216, 216, 0.25);
}
.spectrum-card {
  padding: 26px 50px;
  box-shadow: 3px 8px 15px #eeeeee;
  border: none;
  border-radius: 20px;
  position: relative;
}
.consult_img {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.outsource_img {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
#services li {
  color: rgba(51, 51, 51, 0.95);
  font-family: Inter;
  /* font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (1600 - 300))); */
  font-size: clamp(0.875rem, 0.7763rem + 0.5263vi, 1.25rem);
  margin: 20px 0;
  font-weight: 500;
}
#services li a {
  color: rgba(51, 51, 51, 0.95);
  font-family: Inter;
  font-weight: 500;
  text-decoration: none;
  font-size: clamp(0.875rem, 0.7763rem + 0.5263vi, 1.25rem);
  transition: 0.23s all ease-in-out;
}
#services li a:hover {
  color: #3a65ff;
}

.spectrum_style h3 {
  color: #f8f8f8;
  font-family: Gustavo;
  /* font-size: calc(14px + (30 - 14) * ((100vw - 300px) / (1600 - 300))); */
  font-size: min(
    max(14px, calc(1.625rem + (30 - 14) * ((100vw - 320px) / (1400 - 320)))),
    30px
  );
  font-style: normal;
  font-weight: 500;
  position: absolute;
  /* text-align: center; */
  /* margin: auto; */
  margin-left: 24px;
  /* width: 100%; */
  top: 8%;
}
.spectrum_btn {
  font-size: 20px !important;
}
.spectrum_content {
  padding: 38px 0 0px 54px;
  position: relative;
  visibility: visible;
  transition: 0.50s all ease-in;
  opacity: 1;
}

.spectrum_content ul li {
  animation: newFade 0.55s;
  /* transform: translateY(30px); */
}

.spectrum_padd_style h3 {
  color: var(--primary-13, #131313);
  font-family: Gustavo;
  font-size: calc(14px + (30 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 700;
  margin-bottom: 50px;
}
.spectrum-full-width {
  width: 100% !important;
  height: 450px;
  object-fit: cover;
}
.batch-icon {
  position: absolute;
  right: 0;
  top: -115px;
}
.star-img {
  position: absolute;
  left: 35%;
  width: 46px;
  bottom: 35%;
  animation: rotation 2s infinite linear;
}
.spectrum-card .card {
  margin: 20px 0;
}
.card-item {
  padding-left: 0;
}
.card-item .card-title {
  font-size: min(
    max(14px, calc(0.875rem + (28 - 14) * ((100vw - 320px) / (1440 - 320)))),
    28px
  );
  font-family: gustavo-medium;
  margin-bottom: 0;
}
.card-item .card-text {
  font-size: min(
    max(12px, calc(0.75rem + (18 - 12) * ((100vw - 320px) / (1440 - 320)))),
    18px
  );
  font-family: "Inter", sans-serif;
  padding-top: 12px;
  color: 666666;
}
.spect-img {
  width: 50px;
  height: 50px;
}
.batch-img {
  width: 150px;
}
.vector-img {
  position: absolute;
  top: -162px;
  right: 63px;
}
/****************** Responsive ******************* */
@media screen and (max-width: 1367px) {
  .vector-img {
    right: -21px;
    top: -145px;
    width: 100%;
  }
  .spectrum-sub-title {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 1100px) {
  .batch-icon {
    top: -63px !important;
  }
  .batch-img {
    width: 40% !important;
  }
  .star-img {
    width: 10% !important;
    bottom: 37% !important;
    left: 15% !important;
  }
  .vector-img {
    top: -84px !important;
    right: 126px !important;
    width: 73% !important;
  }
}

@media screen and (min-width: 767px) and (max-width: 1000px) {
  .outsource_img {
    border-top-right-radius: 0;
    border-bottom-right-radius: 16px;
  }
  .tech_img {
    border-bottom-left-radius: 16px !important;
  }
}
@media screen and (max-width: 992px) {
  .vector-img {
    display: none;
  }
  .batch-icon {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .spectrum-card {
    padding: 20px !important;
  }
  .bg-section {
    padding: 30px 0 !important;
  }
}
@media screen and (max-width: 500px) {
  .spectrum-card .card {
    margin: 8px 0 !important;
  }
}
@media screen and (max-width: 767px) {
  .spectrum_content {
    padding: 23px 0 0px 28px;
  }
  .outsource_img {
    border-top-right-radius: 0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  .spectrum-full-width {
    width: 100% !important;
    height: 250px;
  }
  /* .spectrum_source {
        margin: 0 12px;
    }
    .spectrum_tech {
        margin: 0 0px;
    }  */
}
.img-fluid {
  cursor: pointer;
}
/****************** Animation******************* */
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
#technology-content {
  /* transition-timing-function: ease;
    transition-duration: 5s;
    transition-delay: 0s; */
}
@keyframes moveConternt {
  0% {
    left: -30px;
  }
  100% {
    left: 0px;
  }
}
@media (max-width: 991px) {
  .outsource_img {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  .consult_img {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .tech_img {
    border-radius: 0px !important;
  }
  #technology-img,
  #outsourcing-img,
  #consulting-img {
    padding: 0px 12px !important;
  }
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 545px;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid #dcdcdc;
  background: #fff;
  box-shadow: 1px 3px 15px 0px rgba(216, 216, 216, 0.25);
  cursor: pointer;
}

.animation-box {
  height: 100%;
  width: 33.3%;
  /* transition: 0.25s width linear; */
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 0.40s ease, transform 0.40s ease, 0.40s width linear;
  opacity: 1;
}

.shetteractive {
  width: 70% !important  ;
  background-image: unset !important;
  background-size: contain;
  /* opacity: 0.5; */
  /* transition: width 800ms; */
}

.main_image_conent {
  height: 100%;
  width: 100%;
  position: relative;
 

}

.spectrum_content.text-out {
  
   opacity: 0;

}

.spectrum_content h3 {
  font-family: gustavo;
  font-size: clamp(0.9896rem, 0.7292rem + 1.3889vi, 1.9792rem);
  font-weight: 700;
  margin-bottom: 40px;
}
.white_head {
  font-family: gustavo;
  font-size: clamp(0.9896rem, 0.7292rem + 1.3889vi, 1.9792rem);
  font-weight: 700;
  color: #fff;
  position: absolute;
  top: 38px;
  left: 54px;
}
.animation-box.shetteractive::after {
  content: "";
  position: absolute;
  width: 0%;
  height: 4px;
  background: #3a65ff;
  left: 0;
  top: -2px;

  border-radius: 20px;
}



@media screen and (min-width:1100px) and (max-width:1366px){
    .spectrum_content {
        width: 95%;
    }

}

@media screen and (min-width:995px) and (max-width:1056px) {
    .spectrum_content {
        padding: 30px 0 0px 30px;
    }
    .main {
        height: 500px;
    }

}



@media screen and (max-width:992px) {

    .main {
        display: block;
        height: 100%;
        padding: 32px;
        padding-top: 0px;

    }
    .animation-box {

        background: unset !important;

    }
    .animation-box {

           width: 100% !important;
    }
    .white_head {
        display: none;
    }
    .spectrum_content.text-out{

        display: block !important;

    }
    .spectrum_content {
        padding: 0px !important;
        padding-top: 20px !important;
        width: 70%;
    }
    .spectrum_content:nth-child(2), .spectrum_content:nth-child(3){
        padding-top: 0px !important;
    }
}

@media screen and (max-width:540px) {
    .spectrum_content {
        width: 100%;
    }
    .spectrum_content h3 {
        margin-bottom: 20px;
    }
    #services li {
        margin: 8px 0px;
    }
}