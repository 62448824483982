.our_core_header h2{
    font-family: 'Gustavo',sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
 }
 .our_core_header p{
     font-family: 'Inter',sans-serif;
     font-weight: 400;
     font-size: 16px;
     line-height: 23px;
  }
  .our-core-card {
     width: 100%;
     background-color: #FAFAFA;
     margin-bottom: 30px;
     padding: 36px;
     height: 390px !important;
   }
   
   .ecommercetype-div{
     max-width: 100%;
     background-color: #131313;
     height: 462px;
     width: 100%;
     
   }
   .ecommercetype-img-div {
    max-width: 100%;
    height: 462px;
  }
  .ecommercetype-img-div img{
    width: 100%;
    height: 462px;
    object-fit: cover;
  }
  .title-div {
    border-left: 4px solid rgba(217, 217, 217, 0.5);
    cursor: pointer;
  }
  
  .title-div.selected {
    border-color: #3A65FF; 
    background-color: #282828;
  }
  
  .title {
    font-family: Gustavo;
    font-size: 24px;
    font-weight: 400;
    line-height: 38px;
    text-align: left;
    color: #fff;
    opacity: 60%;
  }
  .title.selected {
    opacity: 100%;
  }
  .ecommercetype-img-content-div{
    position: absolute;
    width: 35%;
  }
  .ecommercetype-img-content-div h1{
    font-family: Gustavo;
    font-size: 40px;
    font-weight: 700;
    line-height: 38px;
    text-align: left;
    color: #FFFFFF;

  }
  .content-p1{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #FFFFFF;
    opacity: 95%;
  }
  .content-p2{
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    color: #FFFFFF;
    cursor: pointer;
    border-bottom: 1.4px solid #FFFFFF;
    display: inline;
  }
   @media screen and (max-width:990px) {
    .ecommercetype-img-div img{
        height: 462px;
      }
      .ecommercetype-img-content-div{
        width: 65%;
      }
      .ecommercetype-div{
        height: auto;
      }
     
     .our-core-col:last-child .our-core-card {
       margin-bottom: 0px;
     }
   }
   @media screen and (max-width:1200px) {
    .title-div {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }
   }
   @media screen and (max-width:550px) {
    .ecommercetype-img-content-div{
        width: 100%;
      }
   }
   
   