.about_content_main p{
    font-family: Inter;
    font-size: clamp(1.0625rem, 0.9145rem + 0.7895vi, 1.625rem);
    line-height: 42px;
    color: #5B5B5B;
    /* text-align: justify; */
}
.about_content_main{
  padding-right: 80px;
}
.about_content_main p span{
color: #121212;
font-weight: 700;
}
.count_section_about{
  margin-top: 75px;
  margin-bottom: 30px;
}
.count_about h3{

  font-size: clamp(1.875rem, 1.5132rem + 1.9298vi, 3.25rem);
  font-family: gustavo;
  color: #161616;
  margin: 0px;
  font-weight: 500;

}

.count_about p{
  font-size:  clamp(1rem, 0.9342rem + 0.3509vi, 1.25rem);
  font-family: Inter;
  color:  #313131BF;;
  margin: 0px;
  font-weight: 600;
  
}
.count_about{
  margin-bottom: 50px;
}

@media screen and (max-width: 991px) {

  .about_content_main {
    padding-right: 0px;
  }
  .about_content_main p {
    margin-bottom: 40px;
    text-align: justify;
    line-height: 31px;
    font-size: 16px;
  }
  .count_section_about{
    margin-top: 40px;
  }
  .count_about {
    text-align: center;
  }
  
}

