.about-banner {
    padding: 140px 0;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
}

.banner-title {
    font-size: min(max(20px, calc(1.25rem + (60 - 20) * ((100vw - 320px) / (1400 - 320)))), 60px);
    color: white;
    font-weight: 800;
    margin-bottom: 20px;
    line-height: 52px;
    font-family: gustavo;
}

#color-text {
    color: #8EA6FF !important;
}

@media screen and (max-width:991px) {
    .about-banner {
        padding: 30px 0;
    }
}

@media screen and (max-width:768px) {

    .service-banner {
        padding: 40px 0;
    }

    .banner-title {
        margin-bottom: 12px;
        line-height: 32px;
    }

    .sm-mt-20 {
        margin-top: 20px;
    }
}

@media screen and (max-width:450px) {
    .banner-title {
        margin-bottom: 0px;
        line-height: 32px;
    }

    .service-banner {
        padding: 30px 0;
    }
}