.con-title h1{
    font-family: Gustavo;
    font-size: 40px;
    font-weight: 700;
    line-height: 45px;
    text-align: left;
    color: #131313;
    
}
.con-title p{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #333333;
    opacity: 75%;

}
.right-div-printapp {
    height: 463px;
    background-image: url("../../assets/images/ecommerce-app.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: #F6F6F6;
    padding: 40px !important;
  }
  .printapp-div h3{
    font-family: Gustavo;
    font-size: 28px;
    font-weight: 700;
    line-height: 33.6px;
    text-align: left;
    color: #131313;

  }
  .printapp-div p{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #333333;
    opacity: 75%;

  }
  .count-of-downloads h3{
    font-family: Gustavo;
    font-size: 32px;
    font-weight: 500;
    line-height: 38.4px;
    color: #000000;

  }
  .count-of-downloads p{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
    color: #000000;
    opacity: 60%;

  }
  .View-Case-Study{
    background-color: #3A65FF;
    color: #FFFFFF;
    width: 162px;
    border: none;
    width: 162px;
    height: 41px;
    gap: 8px;
    opacity: 0px;

  }