.tgss-bg{
    background-color: rgba(250, 250, 250, 1) ;
}
.p-50{
    padding:50px !important;
}
.tgss-content h6{
    font-family: gustavo-medium;
    font-size: clamp(1.125rem, 1.034rem + 0.4854vi, 1.5rem);
    color:rgba(72, 72, 72, 1);
    font-weight: 500;
}
.tgss-content p{
    font-family: Inter;
    font-size: clamp(0.875rem, 0.8447rem + 0.1618vi, 1rem);
   ;
    color:rgba(51, 51, 51, 0.75);
    font-weight: 400;
}
.mt-20{
    margin-top: 20px !important;
}
/* .mb-50{
    margin-bottom: 50px !important;
} */

@media screen and (max-width:540px) {
    .p-50 {
        padding: 25px !important;
    }
    .quest_img{
        margin-top: 30px;
    }
    
}
