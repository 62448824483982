.accordion-button{
    background-color: transparent !important;
    font-size: clamp(1.125rem, 1.034rem + 0.4854vi, 1.5rem) !important;
    font-family: gustavo-medium;
    font-weight: 500;
    color: #131313 !important;
    padding: 26px 12px;
}
.accordion{
    padding-left: 36px;
}
.accordion .accordion-item:first-child  .accordion-button{
    padding-top: 0px !important;
}
.accordion-button span{
    flex-basis: 90%;
}
.accordion button.accordion-button {
    padding-left: 0px;
}
.accordion-button.collapsed {
    font-size: clamp(1.125rem, 1.034rem + 0.4854vi, 1.5rem);
    font-family: gustavo-regular;
    font-weight: 400;
    color: #131313;
    opacity: 75%;
    padding-left: 0px;
}
.accordion-button{
    border-color: transparent !important;
    box-shadow: none !important;
}
.accordion-body{
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #131313;
    opacity: 75%;
    width: 85%;
    padding-top: 0px;
    padding-left: 0px;
}
 .accordion-button.collapsed::after{
    
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADDSURBVHgB7ZbNCcMwDIUlJyS5NSN4hWzQjhLITjl6lHaDjlB3g/RmjH8qlZ5r5ZBDwe9iYfz4jOAJIQhljBm99/ecM/R9P83zvEl8LQjlnBuVUhoRIcZ4oisRQMHBqoAK+AMAckK/wfmplJKmFN8+JsQzhc6WPE3TvFqOP9UadohB9KniuxCCPbxFbdd1E88ZwVtNbblyQe260GFLhmEYNgSh1nVlwINr6q1eluUp8dUcVEAF7NgqOJU0tyyvLTzEpL43EvBFr1i+sxMAAAAASUVORK5CYII=" ) !important;

}
.accordion-button::after{
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16"><path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8"/></svg>') !important ;
}

@media screen and (max-width : 800px) {

    .accordion .accordion-item:first-child  .accordion-button{
        padding-top: 20px !important;
    }
    .accordion{
        padding-left: 0px;
    }
}