/* modal */
.loader{
    margin: 15px;
    width: 40px;
}
 
.modal-header{
    border-bottom: none !important;
    padding: 20px 30px 0px 30px !important;
}

.modal-body{
    padding: 20px 30px 0 30px !important;
}

.modal-title{
    font-size: min(max(16px, calc(1rem + (22 - 16) * ((100vw - 320px) / (1400 - 320)))), 22px);

    font-family: gustavo; 
}

.modal-footer{
    border-top: none !important;
    margin-bottom: 30px !important;
}

.shadow_1 {
    width: 200px;
    height: 50px;
    top: 14px !important;
    left: 52px;
    
  }
  .polygon_btn_cont {
    width: 210px;
    height: 57px;
  }
  .polygon_btn {
    width: 200px;
    height: 50px;
    top: 3px;
    left: 4px;
    font-size: 16px;
  
  }

  .shadow_1{
    transition: top 0.3s ease-in-out;
}

.btn_shadow:hover .shadow_1{
    top: 0px !important;
    transition: top 0.3s ease-in-out;

}

@media screen and (max-width: 540px) {
    .shadow_1{
        left: 10px !important;
    }
}


/* greetings */

.greeting_content{
padding-bottom: 30px;
}

.greeting_content img{
    height: 200px;
    width: 200px;
}

.greeting_content h2{
    font-size: min(max(16px, calc(1rem + (32 - 16) * ((100vw - 320px) / (1400 - 320)))), 32px);
    font-family: gustavo;

}
.greeting_content p{
    font-family: "Inter" ;
    color: #999999;
    font-size: min(max(13px, calc(0.8125rem + (16 - 13) * ((100vw - 320px) / (1400 - 320)))), 16px);
    font-weight: 500;
    margin-top: 15px;
}
