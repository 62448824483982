.our-team-card {
    margin-top: 20px;
    border: none;
    border-radius: 0px !important;
    box-shadow: 6px 8px 9px 0px #E0E0E03B;
     /* box-shadow: -4px -4px 4px 0px #F0F0F040; */
     width: 483px;
    background-color: #FFFFFF;
    position: relative;
    top:-150px;
    left: 80px;
}

.our-team-card-title {
    font-family: Gustavo;
    font-size:  clamp(1.25rem, 0.9211rem + 1.7544vi, 2.5rem);
    font-weight: 700;
    line-height: 38px;  
}

.our-team-card-text {
    font-family: Inter;
    font-size: clamp(0.9375rem, 0.8882rem + 0.2632vi, 1.125rem);
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.01em;
    text-align: left;
    opacity: 75%;

}
.our-team-overall-div {
    position: relative;
}
@media only screen and (max-width: 992px) {
    
    .our-team-card {
        position: relative;
        bottom: 0;
        left: 0;
        width: 100%;
        top: 0px !important;
        /* left: 50%; */
        /* width: 100%; */
        padding: 0px !important;
        transition: 1.5s;
        box-shadow: unset !important;
        margin-top: 0px !important;
        margin-bottom: 30px;
    }
    .group_pic{
        height: 230px;
        object-fit: cover;
    }
}
@media only screen and (max-width: 540px) {

    .our-team-card-body {
        padding: 20px !important;
    }
    .our-team-card-text{
        padding-top: 0px !important;

    }

}


