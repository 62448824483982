.our_core_header h2{
   font-family: 'Gustavo',sans-serif;
   font-weight: 700;
   font-size: clamp(1.25rem, 0.9211rem + 1.7544vi, 2.5rem);
   line-height: 40px;
}
.our_core_header p{
    font-family: 'Inter',sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
 }
 .our-core-card {
    width: 100%;
    background-color: #FAFAFA;
    margin-bottom: 30px;
    padding: 36px;
    height: 390px !important;
  }
  
  .our-core-icons {
    margin-bottom: 36px;
  }
  
  .our-core-card-content h2 {
    font-size: clamp(1.125rem, 0.9126rem + 1.1327vi, 32px);
    font-family: gustavo;
    font-weight: 500;
    color: #131313;
    margin-bottom: 22px;
    line-height: 38px;
  }
  
  .our-core-card-content h3 {
    font-size: clamp(0.9375rem, 0.892rem + 0.2427vi, 1.125rem);
    font-family: Inter, 'Arial Narrow Bold', sans-serif;
    font-weight: 400;
    color: #333333;
    opacity: 75%;
    text-align: justify;
    margin-bottom: 24px;
  }
  
  @media screen and (max-width:990px) {
    .our-core-icons {
      height: auto;
      width: 66px;
    }
    .our-core-card {
      padding: 26px;
      height: auto !important;
    }
    .our-core-col:last-child .our-core-card {
      margin-bottom: 0px;
    }
  }
  
  